// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-product-tsx": () => import("./../src/pages/product.tsx" /* webpackChunkName: "component---src-pages-product-tsx" */),
  "component---src-templates-about-tsx": () => import("./../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-index-tsx": () => import("./../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx": () => import("./../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-services-tsx": () => import("./../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */)
}

